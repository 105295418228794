import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_back_button, {
      mode: "md",
      "default-href": "/home"
    }),
    _createCommentVNode(" <ion-button @click=\"goBack\" v-if=\"false\">\n    <ion-icon slot=\"icon-only\" :icon=\"caretBack\" />\n  </ion-button> ")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}