import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container-column"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_ctx.peripheral_icon)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_icon, {
          src: _ctx.peripheral_icon,
          class: "custom_icon",
          color: "primary"
        }, null, 8 /* PROPS */, ["src"]),
        _createVNode(_component_ion_text, {
          color: "primary",
          class: "peripheral-sku"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item.id), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : _createCommentVNode("v-if", true)
}