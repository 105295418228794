// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `/api`;

const onesignal_appId = "ac78fc8b-d999-4d71-acd2-a0d6128a56bc";

const google_key = 'AIzaSyDOrimPpDgaMTm82IiQbkQ9myuLV-DgIx0';


/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'staging.arniaperfetta.app',
  protocol: 'https',
};


export default {
  frontendUrl,
  backendUrl,
  onesignal_appId,
  google_key
};

