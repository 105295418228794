import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c0fb43c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-column" }
const _hoisted_2 = { class: "container form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_Form = _resolveComponent("Form")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.progress)
        ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
            key: 0,
            type: "indeterminate",
            color: "secondary"
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "ion-text-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, { onClick: _ctx.dismiss }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: _ctx.closeCircle
                      }, null, 8 /* PROPS */, ["icon"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, { disabled: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { slot: "icon-only" })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_Form, {
            onSubmit: _ctx.onSubmit,
            initialValues: _ctx.formData
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item_group, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item_divider, { class: "ion-no-padding" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createCommentVNode("               <ion-icon\n                :src=\"peripheral_icon\"\n                slot=\"start\"\n                color=\"primary\"\n              ></ion-icon> "),
                          _createVNode(_component_Field, {
                            name: "name",
                            rules: "required"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_component_ion_input, _mergeProps(field, { type: "text" }), null, 16 /* FULL_PROPS */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ErrorMessage, {
                        name: "name",
                        as: "div",
                        class: "form-error container-column"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_button, {
                  type: "submit",
                  fill: "solid",
                  expand: "full",
                  disabled: _ctx.progress
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.i18n("common_save")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"])
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onSubmit", "initialValues"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}