import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b431a0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_i18n_number = _resolveComponent("i18n-number")!

  return (_ctx.chips)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createCommentVNode(" \n      \n      \n      <ion-chip disabled=\"true\"\n      :item=\"item\"\n      v-for=\"item in badges\"\n      :key=\"item.key\"\n      :color=\"item.color\"\n    >\n      <ion-label v-if=\"item.label\">{{ item.label }}</ion-label>\n    </ion-chip> "),
        (_ctx.chips['battery'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 0,
              disabled: "true",
              color: _ctx.chips['battery']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['battery']['icon']
                }, null, 8 /* PROPS */, ["src"]),
                (_ctx.chips['battery']['label'])
                  ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.chips["battery"]["label"]), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['battery_sibling'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 1,
              disabled: "true",
              color: _ctx.chips['battery_sibling']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['battery_sibling']['icon']
                }, null, 8 /* PROPS */, ["src"]),
                (_ctx.chips['battery_sibling']['label'])
                  ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.chips["battery_sibling"]["label"]), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['temperature'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 2,
              disabled: "true",
              color: _ctx.chips['temperature']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['temperature']['icon']
                }, null, 8 /* PROPS */, ["src"]),
                _createVNode(_component_i18n_number, {
                  value: _ctx.chips['temperature']['value'],
                  fraction: 2,
                  symbol: "°C"
                }, null, 8 /* PROPS */, ["value"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['humidity'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 3,
              disabled: "true",
              color: _ctx.chips['humidity']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['humidity']['icon']
                }, null, 8 /* PROPS */, ["src"]),
                _createVNode(_component_i18n_number, {
                  value: _ctx.chips['humidity']['value'],
                  symbol: "%"
                }, null, 8 /* PROPS */, ["value"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['weight'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 4,
              disabled: "true",
              color: _ctx.chips['weight']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['weight']['icon']
                }, null, 8 /* PROPS */, ["src"]),
                _createVNode(_component_i18n_number, {
                  value: _ctx.chips['weight']['value'],
                  symbol: "Kg",
                  fraction: 3
                }, null, 8 /* PROPS */, ["value"]),
                (_ctx.chips['weight']['diff'])
                  ? (_openBlock(), _createBlock(_component_ion_chip, {
                      key: 0,
                      disabled: "true",
                      color: _ctx.chips['weight']['diff_color']
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_i18n_number, {
                          value: _ctx.chips['weight']['diff'],
                          fraction: 3,
                          signed: true
                        }, null, 8 /* PROPS */, ["value"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["color"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['tilt'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 5,
              disabled: "true",
              color: _ctx.chips['tilt']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['tilt']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['motion'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 6,
              disabled: "true",
              color: _ctx.chips['motion']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['motion']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['tracking'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 7,
              disabled: "true",
              color: _ctx.chips['tracking']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['tracking']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['super_add'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 8,
              disabled: "true",
              color: _ctx.chips['super_add']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['super_add']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['honey_extraction'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 9,
              disabled: "true",
              color: _ctx.chips['honey_extraction']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['honey_extraction']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['nutrition'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 10,
              disabled: "true",
              color: _ctx.chips['nutrition']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['nutrition']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['treatment'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 11,
              disabled: "true",
              color: _ctx.chips['treatment']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['treatment']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['swarming'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 12,
              disabled: "true",
              color: _ctx.chips['swarming']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['swarming']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['health'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 13,
              disabled: "true",
              color: _ctx.chips['health']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['health']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['wind'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 14,
              disabled: "true",
              color: _ctx.chips['wind']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['wind']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['rain'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 15,
              disabled: "true",
              color: _ctx.chips['rain']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['rain']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['sunlight'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 16,
              disabled: "true",
              color: _ctx.chips['sunlight']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['sunlight']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['note'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 17,
              disabled: "true",
              color: _ctx.chips['note']['color']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['note']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true),
        (_ctx.chips['alarm'])
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 18,
              color: _ctx.chips['alarm']['color'],
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetAlarms()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  src: _ctx.chips['alarm']['icon']
                }, null, 8 /* PROPS */, ["src"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["color"]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}