import { ConnectionStatus, Hub, Peripheral, AlarmStatus, PeripheralType, QueenMarker, Sentinel, Position, AlarmType } from "@/models/arniaperfetta";
import { I18nUtil } from '@/shared/i18n/i18n-util';
import { DEFAULT_CENTER } from '@/models/constants';
export class Utils {

    static connection_icon(item: Peripheral) {
        if (!item) {
            return undefined
        }
        if (!item.connection) {
            return undefined
        }
        if (item.connection === ConnectionStatus.OFFLINE) {
            return "assets/mdt-italia/006_offline.svg";
        }
        else {
            return "assets/mdt-italia/005_online.svg";
        }
    }

    static connection_color(item: Peripheral) {
        if (!item) {
            return undefined
        }
        const alarm = item.alarms.find(el => el.type === AlarmType.CONNECTED)
        if (!alarm) {
            return undefined
        }
        if (alarm.status === AlarmStatus.ALARMED) {
            return "danger";
        }
        else if (alarm.status === AlarmStatus.ALERTED) {
            return "warning";
        }
        else if (alarm.status === AlarmStatus.NORMAL) {
            return "connection-normal";
        }
    }

    static item_class(status: AlarmStatus) {
        if (status === undefined || status === AlarmStatus.ALARMED) {
            return "item-danger";
        } else if (status === AlarmStatus.ALERTED) {
            return "item-warning";
        } else {
            return "item-success";
        }
    }

    static peripheral_icon(type: PeripheralType, real = false): string {
        switch (type) {
            case PeripheralType.BHUB:
                if (real) {
                    return "assets/mdt-italia/001_bhub.svg"
                }
                else {
                    return "assets/mdt-italia/002_bsentinel.svg"
                }
            case PeripheralType.BMETEO:
                return "assets/mdt-italia/004_bmeteo.svg"
            case PeripheralType.BSCALE:
                return "assets/mdt-italia/003_bscale.svg"
            case PeripheralType.BSENTINEL:
                return "assets/mdt-italia/002_bsentinel.svg"
            default:
                break;
        }
        return "assets/mdt-italia/000_bee.png";
    }


    static queen_marker_color(marker: QueenMarker | undefined): string | undefined {
        return this.queen_marker_color_ionic(marker)
    }

    static queen_marker_color_ionic(marker: QueenMarker | undefined): string | undefined {
        if (!marker) {
            return undefined
        }
        return `queen_bee_${QueenMarker[marker].toLowerCase()}`
    }

    static queen_marker_style(peripheral: Peripheral): string | undefined {
        let marker: QueenMarker = undefined!;
        if (peripheral.type === PeripheralType.BMETEO || peripheral.type === PeripheralType.BSCALE) {
            return undefined;
        }
        if (peripheral.type === PeripheralType.BHUB) {
            marker = (<Hub>peripheral).queen_marker
        }
        if (peripheral.type === PeripheralType.BSENTINEL) {
            marker = (<Sentinel>peripheral).queen_marker
        }
        return `font-size: 48px ;color: ${this.queen_marker_color(marker)}`
    }

    static queen_marker_color_hex(marker: QueenMarker): string {
        let color = "#ffffff";
        switch (marker) {
            case QueenMarker.BLACK:
                color = "#4e4e4e";
                break;
            case QueenMarker.BLUE:
                color = "#01ccff";
                break;
            case QueenMarker.GREEN:
                color = "#a2c08d";
                break;
            case QueenMarker.PURPLE:
                color = "#997ebe";
                break;
            case QueenMarker.RED:
                color = "#c05151";
                break;
            case QueenMarker.YELLOW:
                color = "#e8c97c";
                break;
            case QueenMarker.WHITE:
                color = "#ffffff";
                break;
            default:
                break;
        }
        return color;
    }

    static center_point(positions: any[]): Position {
        const total = positions.length;
        let X = 0;
        let Y = 0;
        let Z = 0;
        for (const location of positions) {
           
            if (location.lat && location.lng && !isNaN(location.lat) || !isNaN(location.lat) )  {
                const lat = location.lat * Math.PI / 180;
                const lon = location.lng * Math.PI / 180;
                const x = Math.cos(lat) * Math.cos(lon);
                const y = Math.cos(lat) * Math.sin(lon);
                const z = Math.sin(lat);
                X += x;
                Y += y;
                Z += z;
            }
        }

        X = X / total;
        Y = Y / total;
        Z = Z / total;
        const Lon = Math.atan2(Y, X);
        const Hyp = Math.sqrt(X * X + Y * Y);
        const Lat = Math.atan2(Z, Hyp);

        if (isNaN(Lon) || isNaN(Lat)) {
            return DEFAULT_CENTER;
        }

        return { lat: (Lat * 180 / Math.PI), lng: (Lon * 180 / Math.PI) };
    }

}